import { useEffect, useState } from "react";
import { Footer, LandingBlog, Navbar } from "../components";
import { getAllArticlesService } from "../services/blog.services";
import { Box, FormControlLabel, Grid, IconButton, InputBase, Radio, RadioGroup, TextField } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { Helmet } from 'react-helmet';

function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState('Todas');
  const [inputValue, setInputValue] = useState('');
  const [filtroInput, setFiltroInput] = useState('');

  const getBlogEntries = async (currentPage) => {
    try {
      const { success, data } = await getAllArticlesService(currentPage, 9, categorySelected, filtroInput);
      if (success) {
        setBlogs(data.articles);
        setTotalPages(data.totalPages);
        setCategories(data.categories);
      }
    } catch (error) {
      console.log("getBlogEntries -> error: ", error);
    }
  };

  useEffect(() => {
    getBlogEntries(currentPage);
  }, [currentPage, filtroInput, categorySelected]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCategoryChange = (event, value) => {
    setFiltroInput('');
    setCurrentPage(1);
    setCategorySelected(value);
  };

  const handleInputChange = (event) => {
    // setCategorySelected('Todas');
    setInputValue(event.target.value);
  };

  const handleSearch = () => {
    setFiltroInput(inputValue);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Préstamos Personales e Información Financiera</title>
        <meta name="description" content="Aprende a manejar tu dinero y Beloz te provee los recursos para alcanzar tus metas." />
        <meta name="keywords" content="préstamos personales, préstamos sin intereses, crédito personal, préstamos online, préstamos para individuos, préstamos para grupos, línea de crédito, renovable, seguro, digital" />
      </Helmet>
      <Navbar />
      {/* {latestBlog.length > 0 && <LatestEntry latestBlog={latestBlog} />} */}
      {blogs.length > 0 && 
        <Grid container spacing={3} style={{backgroundColor: "rgb(242, 242, 242)"}}>
          <Grid item xs={9}>
            <LandingBlog blogs={blogs} showAll={true} />
          </Grid>
          <Grid item xs={3} style={{ marginTop: '16%' }}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Buscar artículos"
              inputProps={{ 'aria-label': 'buscar artículos' }}
              value={inputValue} // Se asigna el valor del estado al input
              onChange={handleInputChange} // Se actualiza el estado cuando el usuario escribe
            />
            <IconButton
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={handleSearch} // Se ejecuta la búsqueda con el valor actual
            >
              <Search />
            </IconButton>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={categorySelected}
              onChange={handleCategoryChange}
            >
              {categories.map((category) => (
                <FormControlLabel key={category} value={category} control={<Radio />} label={category} />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      }
      {totalPages > 0 && 
        <Box sx={{backgroundColor: "rgb(242, 242, 242)", display: 'flex', justifyContent: 'center', mb: 1.5}}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      }
      <Footer />
    </>
  );
}

export default Blog;
