import { useEffect, useState } from "react";
import {
  Banner,
  Benefits,
  Download,
  FAQ,
  Footer,
  Header,
  Help,
  LandingBlog,
  Navbar,
  PoweredBy,
  Process,
  Testimonials,
  WUs,
} from "../components";
import { NewLoanModal } from "../modals";
import { useAnalytics } from "../hooks/useAnalytics";

function Home({ blogs, publicIP, curpFillType }) {
  const analytics = useAnalytics();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    analytics.sendEvent({
      event: "IniciateCheckout",
      event_category: "ButtonClick",
    });
    analytics.sendEvent({
      event: "AplicaStep1",
      event_category: "ButtonClick",
    });
    analytics.sendEvent({
      event: `AplicaStep1_${curpFillType}`,
      event_category: "ButtonClick",
    });
    setOpen(true);
  };

  useEffect(() => {
    analytics.sendEvent({
      event: "PageViewLanding",
      event_category: "PageViewHome",
    });
  }, []);

  return (
    <>
      <Navbar isHomePage={true} handleOpen={handleOpen} />
      {/* <Header handleOpen={handleOpen} />
      <Download />
      <WUs />
      <Testimonials /> */}
      <Banner handleOpen={handleOpen} />
      <Benefits />
      <Process handleOpen={handleOpen} />
      {blogs.length > 0 && <LandingBlog blogs={blogs} />}
      <PoweredBy />
      <FAQ />
      <Help />
      <Footer handleOpen={handleOpen} />

      <NewLoanModal
        open={open}
        setOpen={setOpen}
        publicIP={publicIP}
        curpFillType={curpFillType}
      />
    </>
  );
}

export default Home;
