import axios from "axios";

const belozV2BE = axios.create({
  baseURL: import.meta.env.VITE_APP_COMMS_ENDPOINT,
  timeout: parseInt(import.meta.env.VITE_APP_BACKEND_TIMEOUT),
});

export const getAllArticlesService = async (page = 1, limit = 9, category = '', filter= '') => {
  try {
    category === 'Todas' ? category = '' : category;
    const { data } = await belozV2BE.get(`/article/articles/search?page=${page}&limit=${limit}&category=${category}&filter=${filter}`);
    let success = true;
    if (!data?.success) success = false;

    return {
      success: success,
      data: success
        ? data.data
        : null,
    };
  } catch (error) {
    console.warn(error);
    return {
      error: error,
      success: false,
    };
  }
};

export const getArticleService = async (postName) => {
  try {
    const { data } = await belozV2BE.get(`/article/${postName}`);
    let success = true;
    if (!data?.success) success = false;
    return {
      success: success,
      data: success ? data.data[0] : null,
    };
  } catch (error) {
    console.warn(error);
    return {
      error: error,
      success: false,
    };
  }
};
